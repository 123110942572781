<template>
  <div id="group-privilege-resve">
    <ejs-dialog
      width="800"
      height="800"
      ref="group-privilege-resve-dialog"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :header="'골프 예약 특전'"
      @close="handleCloseDialog"
    >
      <div class="window golfReservationPrivilege">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">사업장</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          cssClass="lookup-condition-dropdown"
                          :dataSource="
                            bizCodeDropdownListProperties.bizCodeList
                          "
                          :fields="bizCodeDropdownListProperties.bizCodeFields"
                          v-model="argBizCode"
                          @change="handleChangeBizCode($event)"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="gridMembershipGroupPrivilegeRegistrationResve"
                        :provides="gridProvides"
                        :columns="gridColumns"
                        :allowSorting="false"
                        :allowFiltering="false"
                        :isNOColumnDisplay="true"
                        :isSelectedRowRetain="false"
                        :dataSource="dataSource"
                        :editSettings="editSettings"
                        @queryCellInfo="queryCellInfo"
                        @headerCellInfo="GridHeaderCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="handleSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="handleCloseClick"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  getMembershipGroupResve,
  updateMembershipGroupResve,
} from "@/api/memberManagement";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import {
  Edit,
  ForeignKey,
  Group,
  Resize,
  Filter,
} from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {commonCodesGetColorValue, commonCodesGetCommonCode} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "MembershipGroupPrivilegeResvePopup",
  mixins: [commonMixin, confirmDialogMixin],
  props: ["groupId"],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  mounted() {
    this.fetch(true);
  },
  data() {
    return {
      gridProvides: [Edit, ForeignKey, Group, Resize, Filter],
      editSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Top",
        allowFiltering: true,
        allowSorting: true,
      },
      dataSource: [],

      bizCodeDropdownListProperties: {
        bizCodeFields: { text: "ccName", value: "bizCode" },
        bizCodeList: [],
      },

      argBizCode: getCurrentBizCode(),
      comCodesByBenLmttDiv: [],
      isSaved: false,
    };
  },
  computed: {
    gridColumns() {
      return [
        {
          isPrimaryKey: true,
          field: "_rid",
          visible: false,
        },
        {
          allowEditing: false,
          editType: "dropdownedit",
          field: "benItem",
          isCommonCodeField: true,
          headerText: "특전항목",
          textAlign: "Left",
          type: "string",
          width: 120,
          groupCode: "BEN_ITEM",
        },
        {
          allowEditing: false,
          field: "bsnCode",
          headerText: "영업구분",
          textAlign: "Left",
          type: "string",
          width: 90,
          visible: false,
        },
        {
          allowEditing: false,
          field: "bsnCodeName",
          headerText: "영업구분",
          textAlign: "Left",
          type: "string",
          width: 90,
        },
        {
          field: "benLmttDiv",
          editType: "dropdownedit",
          headerText: "제한구분",
          textAlign: "Left",
          type: "string",
          width: 120,
          isCommonCodeField: true,
          dataSource: this.comCodesByBenLmttDiv,
        },
        {
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: {maxLength: 3}, // custom
          field: "lmttCnt",
          headerText: "제한횟수",
          required: true,
          textAlign: "center",
          type: "string",
          width: 90,
        },
      ];
    },
  },
  created() {
    this.comCodesByBenLmttDiv = commonCodesGetCommonCode("BEN_LMTT_DIV", true);
    this.comCodesByBenLmttDiv.unshift({
      comCode: "",
      comName: "",
    });
  },
  methods: {
    hideDialog() {
      this.$refs["group-privilege-resve-dialog"].hide();
    },
    handleCloseDialog() {
      if (this.isSaved) {
        this.$emit("save");
      } else {
        this.$emit("close");
      }
    },
    handleSaveClick() {
      this.updateAllChanged();
    },
    handleCloseClick() {
      this.hideDialog();
    },

    handleChangeBizCode({ e }) {
      if (!e) {
        return;
      }

      this.fetch();
    },
    queryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (
        field === "benLmttDiv" ||
        field === "lmttCnt"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "bsnCodeName") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", data.bsnCode);
      }
      if (data.bsnCode === "%") {
        cell.style.fontWeight = "bold";
        if (field === "benItem") {
          args.rowSpan = 3;
        }
      }
    },

    async fetch(initFlag) {
      try {
        const {
          value: { bizCodeList, membershipGroupReserveList },
        } = await getMembershipGroupResve(
          this.argBizCode,
          this.groupId,
          !!initFlag
        );

        membershipGroupReserveList.forEach((membershipGroupReserve) => {
          !membershipGroupReserve["lmttCnt"] &&
            (membershipGroupReserve["lmttCnt"] = 0);
        });

        if (initFlag) {
          this.bizCodeDropdownListProperties.bizCodeList = bizCodeList;
        }
        // this.dataSource = membershipGroupReserveList;
        this.dataSource = membershipGroupReserveList.map((r, _rid) => {
          return {
            ...r,
            _rid,
          };
        });
      } catch (e) {
        console.error(e);
      }
    },
    async updateAllChanged() {
      const { changedRecords: gridChangedRecords } = this.$refs[
        "gridMembershipGroupPrivilegeRegistrationResve"
      ].patchBatchChanges();

      if (
        !this.$refs.gridMembershipGroupPrivilegeRegistrationResve.validate()
      ) {
        return;
      }

      if (!(gridChangedRecords.length > 0)) {
        this.errorToast("수정된 데이터가 없습니다");
        return;
      }

      const records = this.$refs.gridMembershipGroupPrivilegeRegistrationResve.getBatchCurrentViewRecords();

      const addedRecords = gridChangedRecords
        .map(item => ({
          ...item,
          benId: records.find(record => record._rid === item._rid)?.benId,
          benLmttDiv: item.benLmttDiv ? item.benLmttDiv : records.find(record => record._rid === item._rid)?.benLmttDiv,
          benItem: records.find(record => record._rid === item._rid)?.benItem,
          bsnCode: records.find(record => record._rid === item._rid)?.bsnCode,
        }))
        .filter(({ benId }) => !benId);
      const changedRecords = gridChangedRecords
        .map(item => ({
          ...item,
          benId: records.find(record => record._rid === item._rid)?.benId,
          benLmttDiv: item.benLmttDiv ? item.benLmttDiv : records.find(record => record._rid === item._rid)?.benLmttDiv,
        }))
        .filter(({ benId, benLmttDiv }) => !!benId && !!benLmttDiv);
      const deletedRecords = gridChangedRecords
        .map(item => ({
          ...item,
          benId: records.find(record => record._rid === item._rid)?.benId,
          benLmttDiv: item.benLmttDiv ? item.benLmttDiv : records.find(record => record._rid === item._rid)?.benLmttDiv,
        }))
        .filter(( { benId, benLmttDiv }) => !!benId && !benLmttDiv);

      try {
        await updateMembershipGroupResve(
          this.argBizCode,
          this.groupId,
          addedRecords,
          changedRecords,
          deletedRecords,
        );

        (async () => this.infoToast(this.$t("main.popupMessage.saved")))();

        this.isSaved = true;
        await this.fetch();
      } catch (e) {
        console.error(e);
      }
    },
    GridHeaderCellInfo: function (args) {
      if (
        args.cell.column.field === "benLmttDiv" ||
        args.cell.column.field === "lmttCnt"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
  },
};
</script>
