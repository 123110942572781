import qs from "qs";
import request from "@/utils/request";

/**
 * @return Promise
 */
export function getMembershipGroup(membershipName, memberGradeList) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/standard_info/membership_group",
    method: "get",
    params: {
      membershipName,
      memberGradeList,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

/**
 * @return Promise
 */
export function getMembershipGroupResve(argBizCode, groupId, initFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/standard_info/membership_group/resve",
    method: "get",
    params: {
      argBizCode,
      groupId,
      initFlag,
    },
  });
}

/**
 * @return Promise
 */
export function getMembershipGroupPaymt(argBizCode, groupId, initFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/standard_info/membership_group/paymt",
    method: "get",
    params: {
      argBizCode,
      groupId,
      initFlag,
    },
  });
}

/**
 * @return Promise
 */
export function updateMembershipGroup(
  addedRecords,
  changedRecords,
  deletedRecords
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/standard_info/membership_group",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function updateMembershipGroupResve(
  bizCode,
  groupId,
  addedRecords,
  changedRecords,
  deletedRecords
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/standard_info/membership_group/resve",
    method: "put",
    data: {
      bizCode,
      groupId,
      addedRecords,
      changedRecords,
      deletedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function updateMembershipGroupPaymt(
  bizCode,
  groupId,
  addedRecords,
  changedRecords,
  deletedRecords
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/standard_info/membership_group/paymt",
    method: "put",
    data: {
      bizCode,
      groupId,
      addedRecords,
      changedRecords,
      deletedRecords,
    },
  });
}
