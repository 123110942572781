<template>
  <div id="group-privilege-paymt">
    <ejs-dialog
      width="1180"
      height="800"
      ref="group-privilege-paymt-dialog"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :header="'골프 요금 특전'"
      @close="handleCloseDialog"
    >
      <div class="window golfPricePrivilege">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">사업장</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          cssClass="lookup-condition-dropdown"
                          :dataSource="
                            bizCodeDropdownListProperties.bizCodeList
                          "
                          :fields="bizCodeDropdownListProperties.bizCodeFields"
                          v-model="argBizCode"
                          @change="handleChangeBizCode($event)"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="gridMembershipGroupPrivilegeRegistrationPaymt"
                        :provides="gridProvides"
                        :columns="gridColumns"
                        :allowSorting="true"
                        :allowFiltering="true"
                        :isNOColumnDisplay="true"
                        :isSelectedRowRetain="false"
                        :allowPaging="true"
                        :pageSettings="pageSettings"
                        :dataSource="dataSource"
                        :editSettings="editSettings"
                        :selectionSettings="selectionOptions"
                        :validationRules="GridValidationRules"
                        @cellSaved="cellSaved"
                        @queryCellInfo="queryCellInfo"
                        @headerCellInfo="headerCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="handleSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="handleCloseClick"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  getMembershipGroupPaymt,
  updateMembershipGroupPaymt,
} from "@/api/memberManagement";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import {commonCodesGetColorValue, commonCodesGetCommonCode} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import {
  Edit,
  ForeignKey,
  Group,
  Resize,
  Page,
  Filter,
} from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "MembershipGroupPrivilegePaymtPopup",
  mixins: [commonMixin, confirmDialogMixin],
  props: ["groupId"],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  mounted() {
    this.fetch(true);
    console.log('1.===>', this.$refs.gridMembershipGroupPrivilegeRegistrationPaymt);
  },
  data() {
    return {
      gridProvides: [Edit, ForeignKey, Group, Resize, Page, Filter],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowFiltering: true,
        allowSorting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Top",
      },
      selectionOptions: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      GridValidationRules: {
        paymtCode: {
          custom: {
            method: (args) => {
              const { changedRecords: gridChangedRecords } = this.$refs[
                "gridMembershipGroupPrivilegeRegistrationPaymt"
              ].getBatchChanges();
              const changedRecord = gridChangedRecords.find(
                (data) => args._rid === data._rid
              );
              if (changedRecord) {
                return changedRecord.paymtCode;
              } else {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        paymtCodeSelf: {
          custom: {
            method: (args) => {
              const { changedRecords: gridChangedRecords } = this.$refs[
                  "gridMembershipGroupPrivilegeRegistrationPaymt"
                  ].getBatchChanges();
              const changedRecord = gridChangedRecords.find(
                  (data) => args._rid === data._rid
              );
              if (changedRecord) {
                return changedRecord.paymtCodeSelf;
              } else {
                return true;
              }
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        cartDcMethod: {
          custom: {
            method: (args) => {
              const { cartDcMethod, cartDcAmt } = args;
              return !(cartDcAmt > 0 && !cartDcMethod);
            },
            message: "main.validationMessage.requiredMessage",
            messageValue: ["카트할인방법"],
          },
        },
        cartDcAmt: {
          custom: {
            method: (args) => {
              const { cartDcMethod, cartDcAmt } = args;
              return !(
                cartDcMethod === "RATE" &&
                (cartDcAmt < 1 || cartDcAmt > 100)
              );
            },
            message: "main.validationMessage.rangeMessage",
            messageValue: [1, 100],
          },
        },
      },
      gridColumns: [
        {
          isPrimaryKey: true,
          field: "_rid",
          visible: false,
        },
        {
          allowEditing: false,
          editType: "dropdownedit",
          field: "benTrget",
          isCommonCodeField: true,
          headerText: "특전대상",
          textAlign: "Left",
          width: 120,
          groupCode: "BEN_TRGET",
          type: "string",
        },
        {
          allowEditing: false,
          editType: "dropdownedit",
          field: "bsnCode",
          isCommonCodeField: true,
          headerText: "영업구분",
          textAlign: "Left",
          width: 90,
          groupCode: "BSN_CODE",
          type: "string",
        },
        {
          allowEditing: true,
          editType: "dropdownedit",
          field: "paymtCode",
          isCommonCodeField: true,
          headerText: "요금코드",
          textAlign: "Left",
          width: 150,
          groupCode: "PAYMT_CODE",
          type: "string",
        },
        {
          allowEditing: true,
          editType: "dropdownedit",
          field: "paymtCodeSelf",
          isCommonCodeField: true,
          headerText: "요금코드(셀프)",
          textAlign: "Left",
          width: 150,
          groupCode: "PAYMT_CODE",
          type: "string",
        },
        {
          allowEditing: true,
          editType: "dropdownedit",
          field: "cartDcMethod",
          isCommonCodeField: true,
          headerText: "카트할인방법",
          textAlign: "Left",
          type: "string",
          width: 115,
          dataSource: (() => {
            const result = commonCodesGetCommonCode("DC_METHOD").reduce(
              (acc, val) => {
                if (val.comCode !== "PAYMT") {
                  acc.push(val);
                }
                return acc;
              },
              []
            );
            result.push({ comCode: "", comName: "" });
            return result;
          })(),
        },
        {
          allowEditing: true,
          isNumericType: true,
          field: "cartDcAmt",
          inputNumberProperty: { maxLength: 13 },
          headerText: "카트할인금액(율)",
          textAlign: "right",
          type: "number",
          width: 120,
        },
        {
          allowEditing: true,
          isNumericType: true,
          field: "webOpenTime",
          inputNumberProperty: { maxLength: 3 },
          headerText: "인터넷오픈시간",
          textAlign: "center",
          type: "number",
          width: 120,
        },
      ],
      dataSource: [],
      pageSettings: { pageSize: 50 },

      bizCodeDropdownListProperties: {
        bizCodeFields: { text: "ccName", value: "bizCode" },
        bizCodeList: [],
      },

      argBizCode: getCurrentBizCode(),
    };
  },
  methods: {
    numberWithCommas,
    hideDialog() {
      this.$refs["group-privilege-paymt-dialog"].hide();
    },
    handleCloseDialog() {
      this.$emit("close");
    },
    handleSaveClick() {
      this.updateAllChanged();
    },
    handleCloseClick() {
      this.hideDialog();
    },
    handleChangeBizCode({ e }) {
      if (!e) {
        return;
      }

      this.fetch(false);
    },
    cellSaved(args) {
      const {
        rowData: { cartDcMethod, cartDcAmt },
        cell,
        columnName,
        value,
      } = args;
      if (columnName === "cartDcAmt") {
        cell.innerText = `${this.numberWithCommas(value)}${
          cartDcMethod === "RATE" ? "%" : ""
        }`;
      }
      if (columnName === "cartDcMethod") {
        cell.nextSibling.innerText = `${this.numberWithCommas(cartDcAmt)}${
          value === "RATE" ? "%" : ""
        }`;
      }
    },
    async fetch(initFlag) {
      try {
        const {
          value: { bizCodeList, membershipGroupPaymentList },
        } = await getMembershipGroupPaymt(
          this.argBizCode,
          this.groupId,
          !!initFlag
        );

        membershipGroupPaymentList.forEach((membershipGroupPayment) => {
          !membershipGroupPayment["cartDcAmt"] &&
            (membershipGroupPayment["cartDcAmt"] = 0);
          !membershipGroupPayment["webOpenTime"] &&
            (membershipGroupPayment["webOpenTime"] = 0);
        });

        if (initFlag) {
          this.bizCodeDropdownListProperties.bizCodeList = bizCodeList;
        }
        this.dataSource = membershipGroupPaymentList.map((r, _rid) => {
          return {
            ...r,
            _rid,
          };
        });
      } catch (e) {
        console.error(e);
      }
    },
    async updateAllChanged() {
      const { changedRecords: gridChangedRecords } = this.$refs[
        "gridMembershipGroupPrivilegeRegistrationPaymt"
      ].getBatchChanges();

      if (
        !this.$refs.gridMembershipGroupPrivilegeRegistrationPaymt.validate()
      ) {
        return;
      }

      const addedRecords = gridChangedRecords.filter(({ benId }) => !benId);
      const changedRecords = gridChangedRecords.filter(({ benId }) => !!benId);

      try {
        await updateMembershipGroupPaymt(
          this.argBizCode,
          this.groupId,
          addedRecords,
          changedRecords
        );

        await this.fetch(false);
        this.infoToast(this.$t("main.popupMessage.saved"));
        this.$emit("save");
      } catch (e) {
        console.error(e);
      }
    },
    headerCellInfo(args) {
      const isPaymtCode = args.cell.column.field === "paymtCode";
      const isPaymtCodeSelf = args.cell.column.field === "paymtCodeSelf";
      
      if (isPaymtCode || isPaymtCodeSelf) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    queryCellInfo(args) {
      const {
        data,
        cell,
        column: { field },
      } = args;
      if (
        field === "paymtCode" ||
        field === "cartDcMethod" ||
        field === "cartDcAmt" ||
        field === "webOpenTime" ||
        field === "paymtCodeSelf"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "cartDcAmt") {
        cell.innerText = `${this.numberWithCommas(data.cartDcAmt)}${
          data.cartDcMethod === "RATE" ? "%" : ""
        }`;
      }
      if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", data.bsnCode);
      }
    },
  },
};
</script>
