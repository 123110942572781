<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field memberGrade">
            <div class="title">회원등급</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                  v-model="searchConditions.memberGradeList"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.memberGradeList"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  width="360"
                />
              </li>
            </ul>
          </li>
          <li class="field membershipName">
            <div class="title">회원권명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.membershipName"
                  @keydown.enter="handleClickSearch"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="handleClickSearch"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원권 그룹 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      :is-key-color="true"
                      v-on:click.native="handleSaveClick"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="MembershipGroupPrivilegeRegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      @click.native="handleAddClick"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :ignore="isPopupOpened"
                      :is-custom-shortcut-button="true"
                      shortcut-key="MembershipGroupPrivilegeRegistration.shortcuts.delete"
                      :shortcut="{key: 'F4'}"
                      @click.native="handleDeleteClick"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="gridMembershipGroupPrivilegeRegistration"
                :provides="gridProvides"
                :dataSource="membershipGroupList"
                :columns="gridColumns"
                :editSettings="editSettings"
                :selectionSettings="selectionOptions"
                :allowExcelExport="true"
                @cellSave="cellSave"
                @queryCellInfo="queryCellInfo"
                :validationRules="GridValidationRules"
                @headerCellInfo="GridHeaderCellInfo"
                @recordClick="handleMembershipGroupPrivilegeRegistrationGridClick"
                @actionComplete="onMembershipGroupGridActionComplete"
              >
              </ejs-grid-wrapper>
            </div>
          </div>
        </section>
      </article>
    </div>
    <membership-group-privilege-resve-popup
      v-if="visibleMembershipGroupPrivilegeResvePopup"
      :groupId="membershipGroupPrivilegeResvePopupGroupId"
      @save="handleMembershipGroupPrivilegeResvePopupSave"
      @close="handleMembershipGroupPrivilegeResvePopupClose"
    />
    <membership-group-privilege-paymt-popup
      v-if="visibleMembershipGroupPrivilegePaymtPopup"
      :groupId="membershipGroupPrivilegePaymtPopupGroupId"
      @save="handleMembershipGroupPrivilegePaymtPopupSave"
      @close="handleMembershipGroupPrivilegePaymtPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.membershipName .content .item {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import Vue from "vue";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import {
  Edit,
  ForeignKey,
  Resize,
  VirtualScroll,
  Page,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import {
  getMembershipGroup,
  updateMembershipGroup,
} from "@/api/memberManagement";
import commonMixin from "@/views/layout/mixin/commonMixin";
import MembershipGroupPrivilegeResvePopup from "./MembershipGroupPrivilegeResvePopup";
import MembershipGroupPrivilegePaymtPopup from "./MembershipGroupPrivilegePaymtPopup";

import { MultiSelect } from "@syncfusion/ej2-vue-dropdowns";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

let elem;
let multiSelectObj;

let codeList = commonCodesGetCommonCode("MEMBER_GRADE");

function setCode(args) {
  codeList = codeList.filter((data) => {
    return !args.includes(data.comCode);
  });
}

function initCode(args) {
  const originCodeList = commonCodesGetCommonCode("MEMBER_GRADE");
  const tmpList = args
    .map((data) => {
      return data.memberGradeList;
    })
    .flat();
  codeList = originCodeList.filter((data) => {
    return !tmpList.includes(data.comCode);
  });
}

function addSetCode(args) {
  const originCodeList = commonCodesGetCommonCode("MEMBER_GRADE");
  const tmpList = originCodeList.filter((data) => {
    return args.includes(data.comCode);
  });
  tmpList.forEach((data) => {
    if (codeList.findIndex((code) => code.comCode === data.comCode) === -1) {
      codeList.push(data);
    }
  });
}

export default {
  name: "MembershipGroupPrivilegeRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputText,
    MembershipGroupPrivilegeResvePopup,
    MembershipGroupPrivilegePaymtPopup,
    ejsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      //new edit start
      gridProvides: [
        Edit,
        VirtualScroll,
        Resize,
        ForeignKey,
        Page,
        ExcelExport,
      ],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      selectionOptions: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      GridValidationRules: {
        membershipName: {
          required: true,
          maxLength: 50,
        },
        memberGradeList: {
          required: true,
        },
      },
      gridColumns: [
        {
          allowEditing: true,
          field: "membershipName",
          type: "string",
          headerText: "회원권 그룹명",
          minWidth: 16,
          textAlign: "Left",
          width: 240,
        },
        {
          allowEditing: true,
          field: "issueAmt",
          headerText: "분양금액",
          textAlign: "Right",
          isNumericType: true,
          type: "number",
          width: 120,
        },
        {
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: { maxLength: 5 },
          type: "number",
          field: "sortNo",
          headerText: "정렬",
          textAlign: "Center",
          width: 65,
        },
        {
          allowEditing: true,
          edit: {
            create() {
              elem = document.createElement("input");
              return elem;
            },
            read() {
              setCode(multiSelectObj.value);
              return multiSelectObj.value;
            },
            destroy() {
              multiSelectObj.destroy();
            },
            write(args) {
              addSetCode(args.rowData.memberGradeList);
              multiSelectObj = new MultiSelect({
                dataSource: codeList,
                fields: { text: "comName", value: "comCode" },
                value: args.rowData.memberGradeList,
              });
              multiSelectObj.appendTo(elem);
            },
          },
          field: "memberGradeList",
          headerText: "회원등급 구성",
          type: "string",
          minWidth: 16,
          textAlign: "Left",
          template: () => {
            return {
              template: Vue.component("spanMultiselectString", {
                template: `<span>{{ text }}</span>`,
                data() {
                  return {
                    data: {},
                  };
                },
                computed: {
                  originCodes() {
                    return commonCodesGetCommonCode("MEMBER_GRADE");
                  },
                  text() {
                    if (this.data.memberGradeList) {
                      const tmpList = this.originCodes.filter((code) => {
                        return this.data.memberGradeList.includes(code.comCode);
                      });
                      return tmpList.map((d) => d.comName).join(", ");
                    } else {
                      return null;
                    }
                  },
                },
              }),
            };
          },
          width: 320,
        },
        {
          allowEditing: false,
          field: "membershipGrpResve",
          headerText: "골프예약 특전",
          minWidth: 16,
          textAlign: "Left",
        },
        {
          allowEditing: false,
          field: "membershipGrpResveIcon",
          headerText: "",
          textAlign: "Left",
          width: 32,
        },
        {
          allowEditing: false,
          field: "membershipGrpPaymt",
          headerText: "골프요금 특전",
          minWidth: 16,
          textAlign: "Left",
        },
        {
          allowEditing: false,
          field: "membershipGrpPaymtIcon",
          headerText: "",
          textAlign: "Left",
          width: 32,
        },
      ],
      commonCodeFields: { text: "comName", value: "comCode" },
      //new edit end
      membershipGroupList: [],

      searchConditions: {
        memberGradeList: [],
        membershipName: "",
      },

      // states
      searchOptions: {
        memberGradeList: commonCodesGetCommonCode("MEMBER_GRADE", true),
      },

      // popups
      visibleMembershipGroupPrivilegeResvePopup: false,
      membershipGroupPrivilegeResvePopupGroupId: null,
      visibleMembershipGroupPrivilegePaymtPopup: false,
      membershipGroupPrivilegePaymtPopupGroupId: null,
      membershipGroupListLength: 0,

      count: 0,
      isSearched: false
    };
  },
  async created() {
    await this.fetch();
  },
  computed: {
    isPopupOpened() {
      return (
        this.visibleMembershipGroupPrivilegeResvePopup ||
        this.visibleMembershipGroupPrivilegePaymtPopup
      );
    },
    membershipGradeDropdownProps() {
      return {
        groupCode: "MEMBER_GRADE",
        fields: { text: "comName", value: "comCode" },
      };
    },
  },
  methods: {
    numberWithCommas,
    showMembershipGroupPrivilegeResvePopup() {
      this.visibleMembershipGroupPrivilegeResvePopup = true;
    },
    hideMembershipGroupPrivilegeResvePopup() {
      this.visibleMembershipGroupPrivilegeResvePopup = false;
    },
    showMembershipGroupPrivilegePaymtPopup() {
      this.visibleMembershipGroupPrivilegePaymtPopup = true;
    },
    hideMembershipGroupPrivilegePaymtPopup() {
      this.visibleMembershipGroupPrivilegePaymtPopup = false;
    },

    // API
    async fetch() {
      try {
        const {
          value: { membershipGroupList },
        } = await getMembershipGroup(
          this.searchConditions.membershipName || undefined,
          this.searchConditions.memberGradeList || undefined
        );

        this.membershipGroupList = membershipGroupList;
        this.membershipGroupListLength = membershipGroupList.length;
        initCode(membershipGroupList);
        this.isSearched = true;

      } catch (e) {
        this.isSearched = false;
        console.error(e);
      }
    },

    // event handlers
    handleClickSearch() {
      this.fetch();
    },
    handleAddClick() {
      const gridRef = this.$refs["gridMembershipGroupPrivilegeRegistration"];
      gridRef.addRecord({
        membershipName: "",
        issueAmt: 0,
        sortNo: 0,
        memberGradeList: [],
        membershipGrpResve: "",
        membershipGrpPaymt: "",
      });
    },
    async handleDeleteClick() {
      const deletedGradeList = this.$refs[
        "gridMembershipGroupPrivilegeRegistration"
      ]
        .getSelectedRecords()
        .map((data) => data.memberGradeList);
      addSetCode(deletedGradeList);

      return this.$refs[
        "gridMembershipGroupPrivilegeRegistration"
      ].deleteRecord();
    },
    async handleSaveClick() {
      const { addedRecords, changedRecords, deletedRecords } = this.$refs[
        "gridMembershipGroupPrivilegeRegistration"
      ].getBatchChanges();

      if (!this.$refs["gridMembershipGroupPrivilegeRegistration"].validate()) {
        return;
      }

      try {
        await updateMembershipGroup(
          addedRecords || undefined,
          changedRecords || undefined,
          deletedRecords || undefined
        );

        (async () => this.infoToast(this.$t("main.popupMessage.saved")))();

        await this.fetch();
      } catch (e) {
        console.error(e);
      }
    },

    cellSave(args) {
      if (args.columnName === "issueAmt" && args.columnName === "sortNo") {
        if (isNaN(args.value)) {
          args.value = 0;
        }
      }
    },
    queryCellInfo(args) {
      if (
        args.column.field === "membershipName" ||
        args.column.field === "issueAmt" ||
        args.column.field === "sortNo" ||
        args.column.field === "memberGradeList" ||
        args.column.field === "benContents"
      ) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      } else if (
        args.column.field === "membershipGrpResveIcon" ||
        args.column.field === "membershipGrpPaymtIcon"
      ) {
        args.cell.classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    handleMembershipGroupPrivilegeRegistrationGridClick({ column, rowData }) {
      if (column && column.field === "membershipGrpResveIcon") {
        if (rowData.membershipName === null || rowData.membershipName === "") {
          this.errorToast("저장 후 입력해주세요");
          return;
        }
        this.handleMembershipGrpResveSearchClick(rowData);
      } else if (column && column.field === "membershipGrpPaymtIcon") {
        if (rowData.membershipName === null || rowData.membershipName === "") {
          this.errorToast("저장 후 입력해주세요");
          return;
        }
        this.handleMembershipGrpPaymtSearchClick(rowData);
      }
    },
    async handleMembershipGrpResveSearchClick({ groupId }) {
      this.membershipGroupPrivilegeResvePopupGroupId = groupId;
      this.showMembershipGroupPrivilegeResvePopup();
    },
    async handleMembershipGrpPaymtSearchClick({ groupId }) {
      this.membershipGroupPrivilegePaymtPopupGroupId = groupId;
      this.showMembershipGroupPrivilegePaymtPopup();
    },

    handleMembershipGroupPrivilegeResvePopupSave() {
      this.fetch();
      this.handleMembershipGroupPrivilegeResvePopupClose();
    },
    handleMembershipGroupPrivilegeResvePopupClose() {
      this.hideMembershipGroupPrivilegeResvePopup();
    },
    handleMembershipGroupPrivilegePaymtPopupSave() {
      this.fetch();
      this.handleMembershipGroupPrivilegePaymtPopupClose();
    },
    handleMembershipGroupPrivilegePaymtPopupClose() {
      this.hideMembershipGroupPrivilegePaymtPopup();
    },
    GridHeaderCellInfo: function (args) {
      if (
        args.cell.column.field === "membershipName" ||
        args.cell.column.field === "memberGradeList"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (
        args.cell.column.field === "membershipGrpResveIcon" ||
        args.cell.column.field === "membershipGrpPaymtIcon"
      ) {
        args.node.classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    onMembershipGroupGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.gridMembershipGroupPrivilegeRegistration?.getGridBatchCount() ||
          0
      );
    },
    async onClickExcel() {
      this.$refs.gridMembershipGroupPrivilegeRegistration.excelExport();
    },
  },
};
</script>
